import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import HeroImage from "../images/hero.jpg";
import "../pages/globalStyles.css";

import SiteLogo from "../images/logo.png";
import Peca from "../images/peca.svg";

const HeaderContainer = styled.header`
  width: 100%;
  height: 80px;
  font-size: 1rem;
  background-color: rgba(13, 13, 13, 0.7);
  z-index: 3;
  position: sticky;
  top: 0;
  padding: 0 24px;
  nav {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: white;
      text-decoration: none;
      img {
        height: 60px;
      }
    }
    ul {
      display: flex;
      li {
        margin-left: 16px;
      }
    }
  }
`;

const Header = ({ pageTitle }) => {
  return (
    <>
      <Helmet
        meta={[
          { property: "og:title", content: "Juhyoung lee" },
          {
            property: "og:description",
            content: "Juhyoung Lee portfolio site",
          },
          { property: "og:image", content: { HeroImage } },
        ]}
      >
        <title>Juhyoung Lee | {pageTitle}</title>
        <meta name="description" content="description" />
        <link rel="shortcut icon" href={Peca}></link>
        <script
          src="https://kit.fontawesome.com/82cdd09341.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <HeaderContainer>
        <nav>
          <Link to="/">
            <img src={SiteLogo} alt="Juhyoung Lee"></img>
          </Link>
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/works">Works</Link>
            </li>
          </ul>
        </nav>
      </HeaderContainer>
    </>
  );
};

export default Header;
