import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  width: 100%;
  padding: 0 24px;
  height: 80px;
  display: flex;
  align-items: center;
  margin-top: 80px;
`;

const thisYear = () => {
  const now = new Date();
  return now.getFullYear();
};

const Footer = () => {
  return <FooterContainer>&copy; Juhyoung Lee. {thisYear()}</FooterContainer>;
};

export default Footer;
